exports.onClientEntry = () => {
    if (
        typeof window !== "undefined" &&
        window.navigator &&
        window.document &&
        window.document.body &&
        window.document.body.classList
    ) {
        if (window.navigator.appVersion.indexOf("Win") !== -1) {
            window.document.body.classList.add("windows");
        }
    }
};
