module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mobilní aplikace FIREPORT","short_name":"FIREPORT aplikace","start_url":"/","background_color":"#FC4539","theme_color":"#FC4539","display":"minimal-ui","icon":"src/images/logo_512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a8500493a8365aed28a246576031ee7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","cs","sk","uk"],"defaultLanguage":"cs","fallbackLanguage":"cs","generateDefaultLanguagePage":true,"redirect":true,"siteUrl":"https://fireport.app/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
